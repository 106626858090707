<template>
  <div class="alarmCont">
    <div class="listheader">
      <div class="titleName">设备离线报警</div>
      <span>合计：{{offlineStoresTotal}}</span>
    </div>
    <div class="alarm_panel">
      <div v-if="sos" class="listcount">
        <template v-if="offlineStores.length">
          <div
            v-for="(item,index) in offlineStores"
            :key="index"
            class="list listHover" 
            v-if="$route.meta.title!='万润看板'||$route.meta.title=='万润看板'&&item.count<50"
            @click="offlineClick(item)"
          >
            <div class="hotelName">{{showName(item.hotelName)}}</div>
            <span class="Num">{{item.count}}</span>
          </div>
        </template>
        <template v-else>
          <div class="noData flex flex-center align-center">暂无 设备离线 报警</div>
        </template>
      </div>
      <div v-else class="storesDetails" @click="showAll">
        <div class="storesName" v-if="offlineName">{{showName(offlineName)}}</div>
        <div class="detailsListCont" >
          <!-- <img src="../../assets/loading.gif" alt="" v-if="showDataFlag"> -->
          <div v-if="!showDataFlag">
            <div v-for="(item,index) in roomList" :key="index">
              <!-- 2551 -->
              {{item.floorName}}{{item.roomNumber}}
              房
            </div>
          </div>
           <div v-if="!roomList||roomList.length==0" class="noData2 flex flex-center align-center">暂无 设备离线 报警</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { GET_Offlinesotres, GET_OfflinRoom,GET_Offline_TYPE_1 } from "@/api";
import { showName } from "@/api/common";
export default {
  props: {
    roleType: {
      type: String,
      default: "0",
    },
  },
  data() {
    return {
      offlineStores: [], //sos门店
      sos: true,
      showDataFlag: false,
      offlineName: "", //门店名
      roomList: [],
    };
  },
  computed: {
    offlineStoresTotal() {
      if (this.roleType == 0) {
      return this.offlineStores.reduce( (sum, item)=> {
        let val = item.count ? item.count : 0;
        if(this.$route.meta.title=='万润看板'){
          val=val>50?0:val
          return sum + val;
        }else{
          return sum + val;
        }
      }, 0);
      }else {
        return this.roomList.length
      }
    },
  },

  created() {
    if (this.roleType == 0) {
      this.getlist();
      this.offlineInter = null;
      this.offlineInter = setInterval(() => {
        this.getlist();
      }, 5000);
    } else {
      this.sos = false;
      this.GET_Room_data()
       this.offlineInter = null;
      this.offlineInter = setInterval(() => {
        this.GET_Room_data();
      }, 5000);
    }
  },
  beforeDestroy() {
    clearInterval(this.offlineInter);
  },
  methods: {
    showAll() {
      if (this.roleType == 0) {
        this.sos = true;
        this.showDataFlag = false;
      }
    },
     async GET_Room_data(){
      //  this.showDataFlag=true
       try {
         let res = await GET_Offline_TYPE_1()
         this.offlineName= res.data.data.hotelName
          this.roomList=res.data.data.roomList
           this.showDataFlag=false
       } catch (error) {
          this.showDataFlag=false
       }
    },
    showName(val) {
      // if(this.$route.meta.title=='万润看板') return val
      // else
        return showName(val);
    },
    //sos报警门店
    async getlist(data) {
      let res = await GET_Offlinesotres(data);
      this.offlineStores = res.data.data;
    },
    // 点击报警门店事件
    offlineClick(item) {
      this.sos = false;
      this.showDataFlag = true;
      this.offlineName = item.hotelName;
      this.getRoom(item);
    },
    //sos报警房间
    async getRoom(data) {
      let res = await GET_OfflinRoom(data);
      this.roomList = res.data.data;
      this.showDataFlag = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/mdKanban/index.scss";
@import "@/assets/mdKanban/listData.scss";

</style>