var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "alarmCont" }, [
    _c("div", { staticClass: "listheader" }, [
      _c("div", { staticClass: "titleName" }, [_vm._v("设备离线报警")]),
      _c("span", [_vm._v("合计：" + _vm._s(_vm.offlineStoresTotal))]),
    ]),
    _c("div", { staticClass: "alarm_panel" }, [
      _vm.sos
        ? _c(
            "div",
            { staticClass: "listcount" },
            [
              _vm.offlineStores.length
                ? _vm._l(_vm.offlineStores, function (item, index) {
                    return _vm.$route.meta.title != "万润看板" ||
                      (_vm.$route.meta.title == "万润看板" && item.count < 50)
                      ? _c(
                          "div",
                          {
                            key: index,
                            staticClass: "list listHover",
                            on: {
                              click: function ($event) {
                                return _vm.offlineClick(item)
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "hotelName" }, [
                              _vm._v(_vm._s(_vm.showName(item.hotelName))),
                            ]),
                            _c("span", { staticClass: "Num" }, [
                              _vm._v(_vm._s(item.count)),
                            ]),
                          ]
                        )
                      : _vm._e()
                  })
                : [
                    _c(
                      "div",
                      { staticClass: "noData flex flex-center align-center" },
                      [_vm._v("暂无 设备离线 报警")]
                    ),
                  ],
            ],
            2
          )
        : _c(
            "div",
            { staticClass: "storesDetails", on: { click: _vm.showAll } },
            [
              _vm.offlineName
                ? _c("div", { staticClass: "storesName" }, [
                    _vm._v(_vm._s(_vm.showName(_vm.offlineName))),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "detailsListCont" }, [
                !_vm.showDataFlag
                  ? _c(
                      "div",
                      _vm._l(_vm.roomList, function (item, index) {
                        return _c("div", { key: index }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.floorName) +
                              _vm._s(item.roomNumber) +
                              "\n            房\n          "
                          ),
                        ])
                      }),
                      0
                    )
                  : _vm._e(),
                !_vm.roomList || _vm.roomList.length == 0
                  ? _c(
                      "div",
                      { staticClass: "noData2 flex flex-center align-center" },
                      [_vm._v("暂无 设备离线 报警")]
                    )
                  : _vm._e(),
              ]),
            ]
          ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }